import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import clsx from "clsx";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import "./WeeklyServices.scss";

export default function WeeklyServices({ data, location }) {
  const [selectedServiceID, setSelectedServiceID] = React.useState(null);
  const scrollElement = React.useRef(null);
  const pathName = location.pathname.split("/")[1];

  const {
    markdownRemark: {
      frontmatter: { services },
    },
  } = data;

  const setSelectedService = (serviceID) => {
    if (selectedServiceID === serviceID) {
      setSelectedServiceID(-1);
      return;
    }

    setSelectedServiceID(serviceID);
    setTimeout(
      () => {
        if (window.innerWidth < 576) {
          scrollElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },
      100,
    );
  };

  const isSelected = (serviceID) => {
    return selectedServiceID === serviceID;
  };

  const focusElement = (e) => {
    if (e.getAttribute("aria-expanded") !== "true") {
      setTimeout(() => {
        if (window.innerWidth < 576) {
          e.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 400);
    }
  };

  const selectedService = services.find((service) => service.serviceID === selectedServiceID);

  return (
    <Layout pathName={pathName}>
      <Container>
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="accordion-toggle"
              onClick={(e) => focusElement(e.target)}
            >
              <Card.Title className="accordion-title">Services</Card.Title>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="accordion-body">
                <Row className="row">
                  <Col xs={12} sm={6}>
                    <div>
                      <table className="table table-striped table-bordered table-hover table-condensed text-center">
                        <thead>
                          <tr>
                            <th>
                              <div align="center">Service </div>
                            </th>
                            <th>
                              <div align="center">Price</div>
                            </th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>

                        <tbody>
                          {services.map((service) => (
                            <tr key={service.serviceName}>
                              <td style={{ verticalAlign: "middle" }}>{service.serviceName}</td>
                              <td style={{ verticalAlign: "middle" }}>
                                {service.servicePrice !== "quote" ? "$" + service.servicePrice : ""}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <Button
                                  variant="info"
                                  onClick={() => setSelectedService(service.serviceID)}
                                  className={clsx(
                                    { selected: isSelected(service.serviceID) },
                                    "btn-custom btn-info-custom",
                                  )}
                                >
                                  Details
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} ref={scrollElement}>
                    {selectedService && (
                      <div>
                        <h3>{selectedService.serviceName}</h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: selectedService.serviceDescription }}
                        />
                        <ul>
                          {selectedService.serviceDetails.serviceDetail.map((item) => (
                            <li key={item.serviceDetailID}>{item.detailLine}</li>
                          ))}
                        </ul>
                        <p dangerouslySetInnerHTML={{ __html: selectedService.serviceNotes }} />
                      </div>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              className="accordion-toggle"
              onClick={(e) => focusElement(e.target)}
            >
              <Card.Title className="accordion-title">Pricing Note</Card.Title>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="accordion-body">
                <p />
                <li>
                  Prices above are for a typical pool and could be higher for large or more
                  demanding pools.
                </li>
                <li>
                  The monthly service charges are averaged out based on year-round service and they
                  include the Holiday and Vacation policy outlined below.
                </li>
                <li>
                  Before we make a commitment, we will need to see the pool, test the chemicals and
                  check the equipment. There could be an initial one time extra charge if the pool
                  water balance or equipment condition is not in good enough shape to start regular
                  weekly service.
                </li>
                <p />
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="2"
              className="accordion-toggle"
              onClick={(e) => focusElement(e.target)}
            >
              <Card.Title className="accordion-title">Service Note</Card.Title>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="2">
              <Card.Body className="accordion-body">
                <p />
                <li>
                  It is your responsibility to add or drain water to or from your pool as needed.
                  We will leave a note if we notice there is a problem with the water level.
                </li>
                <li>
                  We will adjust pool pump timers, or ask you to adjust them if we do not have
                  access to the controller, to appropriate daily run times for the season. It is
                  critical for proper filtration and algae prevention.
                </li>
                <li>
                  When selecting one of the lower weekly service levels, you should take into
                  consideration your dedication to performing any service items not included.
                </li>
                <li>
                  We will not be looking specifically for and be responsible for any building,
                  or safety, or other code violations.
                  If that is a concern, we can recommend someone to do inspection of your pool and equipment
                  for such code violations.
                  If we do specific repairs we will make sure that such work is up to code.
                </li>
                <p />
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="3"
              className="accordion-toggle"
              onClick={(e) => focusElement(e.target)}
            >
              <Card.Title className="accordion-title">Holiday Policy</Card.Title>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body className="accordion-body">
                <p>
                  If your regularly scheduled service day falls on the following holidays, we will
                  provide chemical-only service on that day, the day before, or the day after -
                  regardless of your service level. We will take care of anything we notice that
                  requires immediate attention.
                </p>

                <ul>
                  <li>Memorial Day</li>
                  <li>Independence Day</li>
                  <li>Labor Day</li>
                </ul>

                <p />
                <p>
                  We will not be perfoming pool service on the following holidays. We will make sure
                  that pool chemicals will last until the next service day. If you notice any
                  problem contact us and we will schedule time to take care of it.
                </p>

                <ul>
                  <li>Thanksgiving Day and the day after Thanksgiving</li>
                  <li>Christmas Eve and Christmas Day</li>
                  <li>New Year's Eve and New Year's Day</li>
                </ul>

                <p />
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="4"
              className="accordion-toggle"
              onClick={(e) => focusElement(e.target)}
            >
              <Card.Title className="accordion-title">Vacation Policy</Card.Title>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body className="accordion-body">
                <p>
                  We will take up to 4 separate one-week vacations during the year, but not during
                  the critical summer months.
                </p>
                <p>
                  We typically take one week vacation at the end of September / beginning of October,
                  one week between Christmas and New Year, one week at the end of January / beginning of February,
                  and one week at the end of February / beginning of March.
                </p>
                <p>
                  We will make sure your pool is properly balanced and
                  chemicals will last during the time we take off. We will leave a door hanger, or send you email,
                  informing you prior to our vacation time. Our prices reflect us
                  physically servicing your pool 48 weeks in a year.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </Layout>
  );
}

WeeklyServices.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

WeeklyServices.defaultProps = {
  data: null,
  location: null,
};

export const query = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/pages/3-WeeklyServices/" } }) {
      html
      frontmatter {
        services {
          serviceDetails {
            serviceDetail {
              detailLine
              serviceDetailID
            }
          }
          serviceID
          serviceName
          servicePrice
          billPeriod
          serviceNotes
          serviceDescription
        }
      }
    }
  }
`;
